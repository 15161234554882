import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-block p-0 m-0 w-screen-3/4 max-w-prose" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_select = _resolveComponent("base-select")!
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      "data-test-id": "email-invite-form",
      class: "flex items-center w-auto",
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.callSubmitSearch && _ctx.callSubmitSearch(...args)), ["prevent"]))
    }, [
      _createVNode(_component_base_select, {
        modelValue: _ctx.searchType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchType) = $event)),
        "hide-label": true,
        options: ['Journals', 'Publications', 'Users'],
        class: "min-w-max"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_base_input, {
        label: "Search...",
        required: true,
        "hide-label": true,
        modelValue: _ctx.searchOptions.search,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchOptions.search) = $event)),
        class: "w-full pl-2",
        onChange: _ctx.validate
      }, null, 8, ["modelValue", "onChange"]),
      _createElementVNode("button", {
        disabled: _ctx.isSearchDisabled,
        type: "submit",
        class: "invisible",
        onClick: [
          _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.callSubmitSearch && _ctx.callSubmitSearch(...args))),
          _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.isSearchDisabled && _ctx.isSearchDisabled(...args)), ["prevent"]))
        ]
      }, null, 8, _hoisted_2)
    ], 32)
  ]))
}