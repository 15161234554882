import { gql } from '@apollo/client/core';

export const PLAYLISTS = gql`
  query getPlaylists($username: String) {
    playlists(username: $username) {
      id
      title
      description
      published
      itemCount
    }
  }
`;
