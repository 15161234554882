import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cef620d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-sm font-bold pr-2"
}
const _hoisted_2 = ["value", "disabled", "rows", "cols", "placeholder", "maxlength"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass('wrapper ' + _ctx.layout)
  }, [
    (!_ctx.hideLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.title || _ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, [
      _createElementVNode("textarea", {
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        rows: _ctx.rows,
        cols: _ctx.cols,
        placeholder: _ctx.placeholder,
        maxlength: _ctx.maxlength,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
      }, null, 40, _hoisted_2),
      _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
    ]),
    (_ctx.errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, key) => {
            return (_openBlock(), _createElementBlock("p", {
              key: key,
              class: "text-xs text-red-700"
            }, _toDisplayString(error.$message), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}