
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue';
import {CreatePostInput} from "@/types/generated/types";
import {useMutation} from "@vue/apollo-composable";
import linkifyElementText from "@/composables/linkify";
import BaseInput from "@/components/BaseInput/BaseInput.vue";
import {v4 as uuid} from "uuid";
import {v4 as uuid2} from "uuid";
import {maxLength, required} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import {CREATE_POST} from "@/graphql/mutations/createPost";
import {apolloClient} from "@/main";

export default defineComponent({
  components: {BaseInput},
  emits: ['close-sidebar'],
  setup(_, ctx) {
    const contentText = ref();
    const contentTextId = uuid();
    const learnMoreText = ref();
    const learnMoreTextId = uuid2();
    onMounted(() => {
      contentText.value = document.getElementById(contentTextId);
      learnMoreText.value = document.getElementById(learnMoreTextId);
    });

    let newPost = reactive<CreatePostInput>({
      title: '',
      content_text: '',
      learn_more: ''
    });

    const rules = computed(() => {
      return {
        title: { required, maxLength: maxLength(60) },
        content_text: { required, maxLength: maxLength(512) },
        learn_more: { maxLength: maxLength(512) }
      };
    });

    const v$ = useValidate(rules, newPost);

    function validate() {
      v$.value.$validate();
      buttonDisabled.value = v$.value.$errors.length > 0;
    }

    function linkifyContentText() {
      linkifyElementText(contentText.value);
      newPost.content_text = contentText.value.textContent;
      validate();
    }

    function linkifyLearnMoreText() {
      linkifyElementText(learnMoreText.value);
      newPost.learn_more = learnMoreText.value.textContent;
      validate();
    }

    const buttonDisabled = ref(false);
    let createPostErrors: Array<string> = [];

    const { mutate: createPost } = useMutation(CREATE_POST, {
      errorPolicy: 'all'
    });

    function callCreatePost() {
      const variables = {
        input: newPost
      };
      return createPost(variables).then((response) => {
        if (response && response.errors) {
          createPostErrors = JSON.parse(JSON.stringify(response.errors));
          buttonDisabled.value = false;
        } else {
          apolloClient.reFetchObservableQueries();
          callCancelPost();
        }
      });
    }

    function callCancelPost() {
      ctx.emit('close-sidebar');
    }

    return {
      ...toRefs(newPost),
      callCreatePost,
      linkifyContentText,
      linkifyLearnMoreText,
      createPostErrors,
      callCancelPost,
      contentTextId,
      learnMoreTextId,
      buttonDisabled,
      validate,
      v$
    };
  }
});
