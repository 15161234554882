
import {useMutation} from '@vue/apollo-composable';
import {computed, defineComponent, reactive, ref, toRefs} from 'vue';
import {PLAYLISTS} from "@/graphql/queries/playlists";
import {BaseTextarea} from "@/components";
import BaseInput from "@/components/BaseInput/BaseInput.vue";
import {required} from "@vuelidate/validators";
import {PlaylistItemPayload, PlaylistPayload} from "@/types/types";
import useValidate from "@vuelidate/core";
import {CREATE_PLAYLIST} from "@/graphql/mutations/createPlaylist";
import {CREATE_PLAYLIST_ITEM} from "@/graphql/mutations/createPlaylistItem";
import {PLAYLIST} from "@/graphql/queries/playlist";
import {USER_CONNECTIONS} from "@/graphql/queries/userConnections";

export default defineComponent({
  components: {BaseInput, BaseTextarea },
  emits: ['close-form'],
  props: {
    user: {
      type: Object,
      required: true
    },
    itemId: {
      type: String,
      required: false
    },
    itemType: {
      type: String,
      required: false
    },
    showForm: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const rules = computed(() => {
      return {
        input: {
          title: { required },
          description: { required }
        }
      };
    });

    const showNewForm = ref(props.showForm);
    const disabled = ref(true);

    const variables = reactive<PlaylistPayload>({
      input: {
        title: '',
        description: ''
      }
    });
    const v$ = useValidate(rules, variables);

    function validate() {
      createPlaylistErrors.value = [];
      createPlaylistSuccess.value = '';
      v$.value.$validate();
      disabled.value = v$.value.$errors.length > 0;
    }

    let createPlaylistErrors = ref([]);
    let createPlaylistSuccess = ref('');

    const { mutate: createPlaylist } = useMutation(CREATE_PLAYLIST, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: PLAYLISTS, variables: { username: props.user.username }},
        { query: USER_CONNECTIONS, variables: { id: props.user.id} }
      ]
    });

    const playlistItemVariables = reactive<PlaylistItemPayload>({
      input: {
        playlistId: '',
        itemId: '',
        itemType: ''
      }
    });

    const { mutate: createPlaylistItem } = useMutation(CREATE_PLAYLIST_ITEM, {
      errorPolicy: 'all',
      refetchQueries: [{ query: PLAYLIST, variables: { id: playlistItemVariables.input.playlistId } }]
    });

    function callCreatePlaylist() {
      return createPlaylist(variables).then((response) => {
        if (response && response.errors) {
          createPlaylistErrors.value = JSON.parse(JSON.stringify(response.errors));
        } else if (response?.data.createPlaylist) {
          createPlaylistSuccess.value = variables.input.title + ' playlist created.';
          disabled.value = true;
          variables.input.title = '';
          variables.input.description = '';
          showNewForm.value = false;
          if (props.itemId && props.itemType) {
            playlistItemVariables.input.playlistId = response.data.createPlaylist.playlist.id;
            playlistItemVariables.input.itemId = props.itemId;
            playlistItemVariables.input.itemType = props.itemType;
            return createPlaylistItem(playlistItemVariables).then(() => {
              ctx.emit('close-form');
            });
          }
        }
      });
    }

    return {
      ...toRefs(variables),
      v$,
      disabled,
      validate,
      callCreatePlaylist,
      createPlaylistErrors,
      createPlaylistSuccess,
      showNewForm
    };
  }
});
