import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.followQueryLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.follower)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "float-right btn btn-generic",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleFollow && _ctx.toggleFollow(...args)))
            }, " Following "))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "float-right btn btn-primary",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleFollow && _ctx.toggleFollow(...args)))
            }, " Follow "))
      ]))
    : _createCommentVNode("", true)
}