import { gql } from '@apollo/client/core';

export const PUBLISHED_WORK_DETAILS = gql`
  fragment PublishedWorkDetails on PublishedWork {
    id
    publicationTitle
    contentTitle
    summary
    url
    identifierType
    identifier
    imprint
    keywords
    authorNames
    publishedDate
    journalId
  }
`;
