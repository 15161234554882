/* eslint-disable @typescript-eslint/no-explicit-any */
import {reactive, toRefs, Ref} from 'vue';
import { USER_DETAILS } from '@/graphql/queries/userDetails';
import { apolloClient } from '@/main';
import {UserDetails, UserProfile} from "@/types/types";

export const AUTH_KEY = 'regrok_token';
export const AUTH_TOKEN = 'access_token';

// TODO: utilize usertype inside wrapper if possible
const user = reactive<UserProfile>({
    user: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        username: '',
        bio: '',
        website: '',
        role: {
            id: '',
            name: ''
        },
        stakePool: {
            id: '',
            poolTotal: 0,
            stakersCount: 0
        }
    }
});

export const getUserFromApolloStore: () => { user: Ref<UserDetails> } = () => {
    const res = apolloClient.readQuery({
        query: USER_DETAILS
    });
    user.user = res?.user;

    return {
        ...toRefs(user)
    };
};
