import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e270231"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["label", "value", "selected"]
const _hoisted_4 = {
  key: 0,
  disabled: "",
  class: "bg-gray-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(["text-sm font-bold", { 'sr-only': _ctx.hideLabel }])
    }, _toDisplayString(_ctx.label), 3),
    _createElementVNode("select", _mergeProps({
      value: _ctx.modelValue,
      class: [{ field: true }, { 'field-icon': !_ctx.hideIcon }]
    }, {
        ..._ctx.$attrs,
        onChange: ($event) => {
          _ctx.$emit('update:modelValue', $event.target.value);
        }
      }), [
      _renderSlot(_ctx.$slots, "options", {}, () => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            key: option,
            label: option.label,
            value: option,
            selected: option === _ctx.modelValue
          }, _toDisplayString(option), 9, _hoisted_3))
        }), 128)),
        (_ctx.options.length == 0)
          ? (_openBlock(), _createElementBlock("option", _hoisted_4, "No options available"))
          : _createCommentVNode("", true)
      ], true)
    ], 16, _hoisted_2)
  ]))
}