
import {computed, defineComponent, reactive, ref, toRefs} from 'vue';
import { useApi } from '@/services/api';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {BaseTextarea} from "@/components/BaseInput";

interface SupportPayload {
  topic: string;
  subject: string;
  message: string;
}

export default defineComponent({
  components: { BaseInput, BaseTextarea },
  setup() {
    const rules = computed(() => {
      return {
        topic: { required},
        subject: { required},
        message: { required}
      };
    });

    const isDisabled = ref(true);

    const payload = reactive<SupportPayload>({
      topic: '',
      subject: '',
      message: ''
    });

    const v$ = useVuelidate(rules, payload);

    function validate() {
      v$.value.$validate();
      isDisabled.value = v$.value.$errors.length > 0;
    }

    const {
      loading,
      post,
      data
    } = useApi('api/v1/support/send_request');

    const errors = ref('');
    const success = ref('');

    const submit = () => {
      isDisabled.value = true;
      post({ support: payload }).then(() => {
        if (data.value['data']['errors']) {
          errors.value = data.value['data']['errors'];
        } else if (data.value['data']['success']) {
            success.value = data.value['data']['success'];
        } else {
          errors.value = 'An error occurred. Please try again later';
        }
      });
    };

    return {
      ...toRefs(payload),
      submit,
      loading,
      validate,
      v$,
      isDisabled,
      success,
      errors
    };
  }
});
