import { gql } from '@apollo/client/core';

export const USER_DETAILS = gql`
  fragment UserDetails on User {
    id
    email
    username
    firstName
    lastName
    bio
    website
    role {
      name
    }
    publishedGrokCount
    stakePool {
      id
      poolTotal
      stakersCount
    }
    interests {
      id
      interest
    }
    topics {
      id
      topic
    }
  }
`;
