import {relayStylePagination} from "@apollo/client/utilities";

export const typePolicies = {
  typePolicies: {
    Query: {
      fields: {
        activities: relayStylePagination(),
        groks: relayStylePagination(),
        journals: relayStylePagination(),
        notifications: relayStylePagination(),
        publishedWorks: relayStylePagination(),
        journalArticles: relayStylePagination()
      }
    }
  }
};
