import linkifyHtml from "linkifyjs/lib/linkify-html";
import 'linkify-plugin-hashtag';
import 'linkify-plugin-mention';

const options = {
  defaultProtocol: 'https',
  target: {
    url: '_blank'
  },
  formatHref: {
    hashtag: (val) => `discover/${val.replace('#', '')}`,
    mention: (val) => `user${val.replace('@', '')}`
  }
};

export default function linkifyElementText(element: HTMLElement) : void {
  const originalText = element.textContent || '';
  element.innerHTML = linkifyHtml(originalText, options);
  const selection = window.getSelection();
  const range = document.createRange();
  selection?.removeAllRanges();
  range.selectNodeContents(element);
  range.collapse(false);
  selection?.addRange(range);
  element.focus();
}
