import { gql } from '@apollo/client/core';

export const COMMENT_DETAILS = gql`
  fragment CommentDetails on Comment {
    id
    reflection
    publishedAt
    user {
      id
      username
    }
    commentsCount
    baseCommentableType
    baseCommentableId
  }
`;
