import { gql } from '@apollo/client/core';
import { GROK_DETAILS as GrokDetailsFragment } from '../fragments/grok';
import { PUBLISHED_WORK_DETAILS as PublishedWorkDetailsFragment } from '../fragments/publishedWork';
import { PAGE_INFO as PageInfoFragment } from '../fragments/pageInfo';

export const PLAYLIST = gql`
  ${GrokDetailsFragment}
  ${PublishedWorkDetailsFragment}
  ${PageInfoFragment}
  query getPlaylist($id: ID!) {
    playlist(id: $id) {
      pageInfo {
        ...PageInfoDetails
      }
      nodes {
        id
        order
        title
        grok {
          ...GrokDetails
        }
        publishedWork {
          ...PublishedWorkDetails
        }
      }
      edges {
        node {
          id
          order
          title
          grok {
            ...GrokDetails
          }
          publishedWork {
            ...PublishedWorkDetails
          }
        }
      }
    }
  }
`;
