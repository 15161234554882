import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row w-full" }
const _hoisted_2 = { class: "flex flex-row" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text-red-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", {
      "data-test-id": "email-invite-form",
      class: "flex flex-col",
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.callCreateInvite && _ctx.callCreateInvite(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_base_input, {
          modelValue: _ctx.newInvite.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newInvite.email) = $event)),
          label: "Email Address",
          type: "text",
          class: "w-full mr-2",
          errors: _ctx.v$.$errors,
          onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validate()))
        }, null, 8, ["modelValue", "errors"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          disabled: _ctx.isAddEmailButtonDisabled,
          type: "submit",
          class: "btn btn-primary relative",
          "data-test-id": "add-email-to-invite-list",
          onClick: [
            _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.callCreateInvite && _ctx.callCreateInvite(...args))),
            _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.isAddEmailButtonDisabled && _ctx.isAddEmailButtonDisabled(...args)), ["prevent"]))
          ]
        }, " Invite to ReGrok ", 8, _hoisted_3)
      ])
    ], 32),
    (_ctx.createInvitationErrors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.createInvitationErrors, (error) => {
            return (_openBlock(), _createElementBlock("div", {
              key: error.message
            }, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(error.message), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}