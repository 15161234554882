import { InjectionKey, Ref } from 'vue';

export interface UserAccountPayload {
  input: {
    email?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    bio?: string;
    website?: string;
    interests?: [string];
    topics?: [string];
  };
}

export interface UserDetails {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  bio?: string;
  website?: string;
  role: {
    id: string;
    name: string;
  },
  stakePool: {
    id: string,
    poolTotal: number,
    stakersCount: number
  }
}

export interface UserProfile {
  user: UserDetails
}

export interface GrokPayload {
  input: {
    id?: string,
    title?: string;
    description?: string;
    s3OriginPath?: string;
    references?: [{
      description: string;
      url: string;
    }];
    keywords?: [string];
    preview?: boolean;
  }
}

export interface GrokReferencePayload {
  description: string;
  url: string;
}

export interface GrokReviewPayload {
  input: {
    id: string,
    comments: string;
    approved: boolean;
    flagged?: boolean;
  }
}

export interface GrokUpdateStatusInput {
  input: {
    id: string,
    draft?: boolean;
    published?: boolean;
  }
}

export interface PlaylistPayload {
  input: {
    id?: string;
    title?: string,
    description?: string;
    published?: boolean;
  }
}

export interface PlaylistItemPayload {
  input: {
    playlistId: string,
    itemId: string,
    itemType: string
  }
}

export interface StakerPayload {
  input: {
    stakePoolId: string,
    tokens: number,
    withdrawal?: boolean
  }
}

export const UserKey: InjectionKey<Ref<UserDetails>> = Symbol('User');
export const PublicPathKey: InjectionKey<Ref<string>> = Symbol('PublicPath');
