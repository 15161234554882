import { gql } from '@apollo/client/core';

export const USER_CONNECTIONS = gql`
  query getUserConnections($id: ID) {
    userConnections(id: $id) {
      id
      userFollowsCount
      journalFollowsCount
      playlistFollowsCount
      userFollowedByCount
      publishedPlaylistCount
      playlistCount
      followingYou
      followedByYou
    }
  }
`;
