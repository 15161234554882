
import {defineComponent, inject, reactive, ref, toRefs} from 'vue';
import {PlaylistItemPayload, UserKey} from "@/types/types";
import {useMutation, useQuery, useResult} from "@vue/apollo-composable";
import {PLAYLISTS} from "@/graphql/queries/playlists";
import {PLAYLIST} from "@/graphql/queries/playlist";
import {CREATE_PLAYLIST_ITEM} from "@/graphql/mutations/createPlaylistItem";
import PlaylistForm from "@/components/UserProfile/PlaylistForm.vue";

export default defineComponent({
  components: { PlaylistForm },
  emits: ['close-sidebar'],
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const user = inject(UserKey);

    const {
      result,
      loading
    } = useQuery(PLAYLISTS, { username: user?.value.username });

    const playlistOptions = useResult(result, null);

    const disabled = ref(true);

    const variables = reactive<PlaylistItemPayload>({
      input: {
        playlistId: '',
        itemId: props.id,
        itemType: props.type
      }
    });

    function validate() {
      createPlaylistItemErrors.value = [];
      disabled.value = variables.input.playlistId.length === 0;
    }

    let createPlaylistItemErrors = ref([]);

    const { mutate: createPlaylistItem } = useMutation(CREATE_PLAYLIST_ITEM, {
      errorPolicy: 'all',
      refetchQueries: [{ query: PLAYLIST, variables: { id: variables.input.playlistId } }]
    });

    function callCreatePlaylistItem() {
      return createPlaylistItem(variables).then((response) => {
        if (response && response.errors) {
          createPlaylistItemErrors.value = JSON.parse(JSON.stringify(response.errors));
        } else {
          closeSidebar();
        }
      });
    }

    function closeSidebar() {
      ctx.emit('close-sidebar');
    }

    return {
      disabled,
      callCreatePlaylistItem,
      createPlaylistItemErrors,
      ...toRefs(variables),
      validate,
      playlistOptions,
      loading,
      closeSidebar,
      user
    };
  }
});
