
import {defineComponent, reactive, ref, watch} from 'vue';
import {BaseInput, BaseSelect} from '@/components';
import router from "@/router";
import {useRoute} from "vue-router";
import {mixin as VueClickAway} from "vue3-click-away";

export default defineComponent({
  components: { BaseInput, BaseSelect },
  emits: ['close'],
  mixins: [VueClickAway],
  props: {
    search: {
      type: String,
      required: false
    }
  },
  setup(props, ctx) {
    const route = useRoute();
    const searchType = ref('Publications');

    watch(() => props.search, () => {
      searchOptions.search = '';
    });
    watch(() => route.name, () => {
      if (route.name === 'users') {
        searchType.value = 'Users';
      } else if (route.name === 'journals') {
        searchType.value = 'Journals';
      } else {
        searchType.value = 'Publications';
      }
    });

    const searchOptions: { search: string } = reactive({
      search: ''
    });

    function cleanSearchTerm(searchTerm: string | null | undefined) : string {
      return searchTerm ? searchTerm : '';
    }

    let isSearchDisabled = ref(true);

    function validate() {
      isSearchDisabled.value = false;
    }

    function callSubmitSearch() {
      const searchTerm = cleanSearchTerm(searchOptions.search).toString();
      let pathName = searchType.value.toLowerCase();
      router.push({ name: pathName, params: { search: searchTerm }});
      close();
    }

    function close() {
      ctx.emit('close');
    }

    return {
      searchOptions,
      isSearchDisabled,
      validate,
      callSubmitSearch,
      close,
      searchType
    };
  }
});
