import { gql } from '@apollo/client/core';

export const CREATE_PLAYLIST_ITEM = gql`
  mutation CreatePlaylistItem($input: CreatePlaylistItemInput!) {
    createPlaylistItem(input: $input) {
      playlist {
        id
      }
    }
  }
`;
