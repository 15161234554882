
import {useMutation, useQuery, useResult} from '@vue/apollo-composable';
import {defineComponent, inject, ref} from 'vue';
import {CREATE_FOLLOW} from "@/graphql/mutations/createFollow";
import {DELETE_FOLLOW} from "@/graphql/mutations/deleteFollow";
import {FOLLOW} from "@/graphql/queries/follow";
import {UserKey} from "@/types/types";
import {USER_CONNECTIONS} from "@/graphql/queries/userConnections";

export default defineComponent({
  emits: ['updated'],
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const currentUser = inject(UserKey);
    const variables = ref({ id: props.id, type: props.type });

    const {
      result: followQueryResult,
      loading: followQueryLoading
    } = useQuery(FOLLOW, variables.value);

    const follower = useResult(followQueryResult, null);

    const { mutate: follow } = useMutation(CREATE_FOLLOW, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: FOLLOW, variables: variables.value },
        { query: USER_CONNECTIONS, variables: { id: currentUser?.value.id} }
      ]
    });

    const { mutate: unfollow } = useMutation(DELETE_FOLLOW, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: FOLLOW, variables: variables.value },
        { query: USER_CONNECTIONS, variables: { id: currentUser?.value.id} }
      ]
    });

    function toggleFollow() {
      let input = { input: variables.value };
      if (follower.value) {
        return unfollow(input).then(() => {
          ctx.emit('updated');
        });
      } else {
        return follow(input).then(() => {
          ctx.emit('updated');
        });
      }
    }

    return {
      follower,
      followQueryLoading,
      toggleFollow
    };
  }
});
