
import {defineComponent, inject, onMounted, ref} from 'vue';
import {UserKey} from "@/types/types";
import FollowInfo from "@/components/UserProfile/FollowInfo.vue";
import linkifyElementText from "@/composables/linkify";

export default defineComponent({
  name: 'PersonalInfoDescription',
  components: {FollowInfo},
  emits: ['close-sidebar'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    let user = inject(UserKey);
    let currentUser = ref(props.user.id === user?.value.id);
    const element = ref();
    const elementId = props.user.id + 'show-user-bio';
    onMounted(() => {
      element.value = document.getElementById(elementId);
      element.value.innerHTML = props.user.bio;
      linkifyElementText(element.value);
    });

    function closeSidebar() {
      ctx.emit('close-sidebar');
    }

    return { currentUser, closeSidebar, elementId };
  }
});
