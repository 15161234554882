
import {defineComponent} from 'vue';
import {useQuery, useResult} from "@vue/apollo-composable";
import router from "@/router";
import ManageFollow from "@/components/ManageFollow.vue";
import {USER_CONNECTIONS} from "@/graphql/queries/userConnections";

export default defineComponent({
  components: { ManageFollow },
  name: 'FollowInfo',
  emits: ['close-sidebar'],
  props: {
    user: {
      type: Object,
      required: true
    },
    currentUser: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const {
      result: followQueryResult,
      loading: followQueryLoading,
      refetch
    } = useQuery(USER_CONNECTIONS, { id: props.user.id });

    const followDetails = useResult(followQueryResult, 'user');

    function showUser(details: string) : void {
      let route = '/user/' + props.user.username + details;
      ctx.emit('close-sidebar');
      router.push(route);
    }

    return { followDetails, followQueryLoading, showUser, refetch };
  }
});
